import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },
  //!----------------Products --------

  {
    id: "Product",
    title: "Product",
    type: "item",
    icon: <Icon.Archive size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/product/list",
  },
  // //!----------------Gifts--------

  // {
  //   id: "gift",
  //   title: "Gift",
  //   type: "collapse",
  //   icon: <Icon.Gift size={15} />,
  //   children: [
  //     {
  //       id: "giftTypeList",
  //       title: "Gift Types",
  //       type: "item",
  //       icon: <Icon.Gift size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/gift-type/list",
  //     },
  //     {
  //       id: "giftProducts",
  //       title: "Gift Products",
  //       type: "item",
  //       icon: <Icon.Gift size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/gift-products/list",
  //     },
  //   ],
  // },

  //!----------------Product highlights--------

  // {
  //   id: "gift",
  //   title: "Gift",
  //   type: "collapse",
  //   icon: <Icon.Gift size={15} />,
  //   children: [
  //     {
  //       id: "giftTypeList",
  //       title: "Gift Types",
  //       type: "item",
  //       icon: <Icon.Gift size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/gift-type/list",
  //     },
  //     {
  //       id: "giftProducts",
  //       title: "Gift Products",
  //       type: "item",
  //       icon: <Icon.Gift size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/gift-products/list",
  //     },
  //   ],
  // },
  // !---------------- Best Selling Products--------
  {
    id: "BestSellingProducts",
    title: "Best Selling Products",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/best-selling-products",
  },
  // !---------------- Featured Products--------
  {
    id: "FeaturedProducts",
    title: "Top Shelf Picks",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/featured-products",
  },
  // !---------------- Testimonials--------
  {
    id: "testimoniallist",
    title: "Testimonials",
    type: "item",
    icon: <Icon.Star size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/testimonial/list",
  },
  //!----------------Blogs--------
  {
    id: "blogs",
    title: "Blogs",
    type: "collapse",
    icon: <Icon.Book size={15} />,
    children: [
      {
        id: "blogList",
        title: "Blog",
        type: "item",
        icon: <Icon.AlertTriangle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/blog/list",
      },
      {
        id: "blogCategory",
        title: "Blog Category",
        type: "item",
        icon: <Icon.AlignCenter size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/blog-category/list",
      },
      {
        id: "commentslist",
        title: "Comments List",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/comments",
      },
    ],
  },
  //!----------------News --------

  {
    id: "newsList",
    title: "News",
    type: "item",
    icon: <Icon.FileText size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/news/list",
  },
  //!----------------Featured On --------

  {
    id: "featuredOnList",
    title: "Featured On",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/featuredOn/list",
  },
  //!----------------Variations --------
  {
    id: "variationsList",
    title: "Variations List",
    type: "collapse",
    icon: <Icon.AlignJustify size={15} />,
    children: [
      {
        id: "Variation",
        title: "Variation",
        type: "item",
        icon: <Icon.AlignLeft size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/variation/list",
      },
      {
        id: "VariationValue",
        title: "Variation Values",
        type: "item",
        icon: <Icon.AlignLeft size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/variation-value/list",
      },
    ],
  },
  //!----------------Category --------
  {
    id: "category",
    title: "Category",
    type: "collapse",
    icon: <Icon.AlignJustify size={15} />,
    children: [
      {
        id: "Category",
        title: "Category",
        type: "item",
        icon: <Icon.AlignJustify size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/category/list",
      },
      {
        id: "SubCategory",
        title: "Sub Category",
        type: "item",
        icon: <Icon.AlignLeft size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/sub-category/list",
      },
    ],
  },
  //!----------------Brands--------
  {
    id: "brands",
    title: "Brands List",
    type: "item",
    icon: <Icon.Award size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/brands/list",
  },
  //!----------------Promo Cdes--------
  {
    id: "promoCodes",
    title: "Coupon Codes",
    type: "item",
    icon: <Icon.CheckCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/promo-codes/list",
  },
  // !----------------Offers--------
  {
    id: "offers",
    title: "Offers List",
    type: "item",
    icon: <Icon.CheckCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/offers/list",
  },
  //!---------------Reviews------
  {
    id: "reviews",
    title: "Reviews",
    type: "item",
    icon: <Icon.Star size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/reviews",
  },
  //!--------------faqs-------
  {
    id: "FAQS",
    title: "FAQ's",
    type: "item",
    icon: <Icon.HelpCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/faqs",
  },
  // !--------------Orders List--------
  {
    id: "orderslist",
    title: "Orders",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/orders/list",
  },
  // // !--------------Sales--------
  // {
  //   id: "saleslist",
  //   title: "Sales",
  //   type: "item",
  //   icon: <Icon.DollarSign size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/sales/list",
  // },

  // // !--------------Product Report--------
  // {
  //   id: "productReportlist",
  //   title: "Product Report",
  //   type: "item",
  //   icon: <Icon.List size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/product-report/list",
  // },
  //!----------------Platinum Club--------
  {
    id: "ProductQuotationsList",
    title: "Product Quotations",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/product-quotations",
  },
  //!----------------Platinum Club--------
  {
    id: "PlatinumFormList",
    title: "Platinum Club Members",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/platinum-club-list",
  },
  //!----------------contact list--------
  {
    id: "ContactUsFormList",
    title: "Contact Us",
    type: "item",
    icon: <Icon.Phone size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/form-list",
  },
  //!----------------subscribers List--------
  {
    id: "SubscribersList",
    title: "Subscribers",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/subscribers-list",
  },
  //!---------------Users------
  {
    id: "users",
    title: "Registered Users",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/users-list",
  },

  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
  //! -----------Home Page---------
  // {
  //   id: "homePage",
  //   title: "Home",
  //   type: "item",
  //   icon: <Icon.Home size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/home-page",
  // },
  // //!--------------static Pages list-------
  // {
  //   id: "pages",
  //   title: "Pages",
  //   type: "item",
  //   icon: <Icon.Paperclip size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/pages",
  // },
  //! -----------Static Pages---------
  // {
  //   id: "staticPages",
  //   title: "Static Pages",
  //   type: "collapse",
  //   icon: <Icon.Anchor size={15} />,
  //   children: [
  //     {
  //       id: "About",
  //       title: "About Us",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/about",
  //     },
  //     {
  //       id: "privacyPolicy",
  //       title: "Privacy Policy",
  //       type: "item",
  //       icon: <Icon.List size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/privacy-policy",
  //     },
  //     {
  //       id: "TermsConditions",
  //       title: "Terms & Conditions",
  //       type: "item",
  //       icon: <Icon.List size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/terms-and-conditions",
  //     },

  //     // {
  //     //   id: "contact",
  //     //   title: "Contact",
  //     //   type: "item",
  //     //   icon: <Icon.Phone size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/contact",
  //     // },
  //     // {
  //     //   id: "refundPolicy",
  //     //   title: "Refund Policy",
  //     //   type: "item",
  //     //   icon: <Icon.List size={12} />,
  //     //   permissions: ["admin", "editor"],
  //     //   navLink: "/refund-policy",
  //     // },
  //   ],
  // },

  //!------------Static Pages--------
  ,
];

export default navigationConfig;
